import { default as _91userId_93PvcoCCpsMZMeta } from "/application/pages/account/[rootOrgId]/users/[userId].vue?macro=true";
import { default as create6L0etRSGofMeta } from "/application/pages/account/[rootOrgId]/users/create.vue?macro=true";
import { default as indexgPHpmbO3gGMeta } from "/application/pages/account/[rootOrgId]/users/index.vue?macro=true";
import { default as _91roleId_93Pky331Ig9KMeta } from "/application/pages/account/roles/[roleId].vue?macro=true";
import { default as indextxRtqKzy3pMeta } from "/application/pages/account/roles/index.vue?macro=true";
import { default as accountCAl8jnkHMlMeta } from "/application/pages/account.vue?macro=true";
import { default as _91id_93epgNYW2QfZMeta } from "/application/pages/dashboard/[id].vue?macro=true";
import { default as _91id_93C5rDZK3MjAMeta } from "/application/pages/dashboard/comment/[id].vue?macro=true";
import { default as dashboardDR0w88dMpIMeta } from "/application/pages/dashboard.vue?macro=true";
import { default as indexUEHw9xstOJMeta } from "/application/pages/index.vue?macro=true";
import { default as _91_91rootOrgId_93_939QzGTp7E9vMeta } from "/application/pages/library/[[rootOrgId]].vue?macro=true";
import { default as loginFLbOmRceC3Meta } from "/application/pages/login.vue?macro=true";
import { default as releasesGNFCh5NG2yMeta } from "/application/pages/releases.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: accountCAl8jnkHMlMeta || {},
    component: () => import("/application/pages/account.vue"),
    children: [
  {
    name: "account-rootOrgId-users-userId",
    path: ":rootOrgId()/users/:userId()",
    component: () => import("/application/pages/account/[rootOrgId]/users/[userId].vue")
  },
  {
    name: "account-rootOrgId-users-create",
    path: ":rootOrgId()/users/create",
    meta: create6L0etRSGofMeta || {},
    component: () => import("/application/pages/account/[rootOrgId]/users/create.vue")
  },
  {
    name: "account-rootOrgId-users",
    path: ":rootOrgId()/users",
    meta: indexgPHpmbO3gGMeta || {},
    component: () => import("/application/pages/account/[rootOrgId]/users/index.vue")
  },
  {
    name: "account-roles-roleId",
    path: "roles/:roleId()",
    component: () => import("/application/pages/account/roles/[roleId].vue")
  },
  {
    name: "account-roles",
    path: "roles",
    component: () => import("/application/pages/account/roles/index.vue")
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/application/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-id",
    path: ":id()",
    component: () => import("/application/pages/dashboard/[id].vue")
  },
  {
    name: "dashboard-comment-id",
    path: "comment/:id()",
    component: () => import("/application/pages/dashboard/comment/[id].vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/application/pages/index.vue")
  },
  {
    name: "library-rootOrgId",
    path: "/library/:rootOrgId?",
    component: () => import("/application/pages/library/[[rootOrgId]].vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginFLbOmRceC3Meta || {},
    component: () => import("/application/pages/login.vue")
  },
  {
    name: "releases",
    path: "/releases",
    meta: releasesGNFCh5NG2yMeta || {},
    component: () => import("/application/pages/releases.vue")
  }
]