import type { DashboardLibraryItem } from '~/types'
import type { HubCommentDashboard, HubDashboard, HubDashboardMetadata } from '~/types/configuration'

export const useDashboardStore = defineStore('dashboard', () => {
  const organisationStore = useOrganisationStore()
  const { currentOrganisation } = storeToRefs(organisationStore)

  // refs
  const currentDashboardId: Ref<string | undefined> = ref()
  const dashboardByOrganisationList: Ref<Array<{
    rootOrgId: number
    dashboards: Array<DashboardLibraryItem | HubDashboard | HubCommentDashboard>
  }>> = ref([])

  // computed
  const allDashboards: ComputedRef<Array<DashboardLibraryItem | HubDashboard | HubCommentDashboard>> = computed(() => {
    return dashboardByOrganisationList.value.flatMap(item => item.dashboards)
  })
  const currentOrganisationDashboards: ComputedRef<Array<DashboardLibraryItem | HubDashboard | HubCommentDashboard>>
    = computed(() => {
      return dashboardByOrganisationList.value
        .find(item => item.rootOrgId === currentOrganisation.value?.item.organisationNodeId)?.dashboards
        || []
    })
  const currentOrganisationCommentDashboards: ComputedRef<Array<HubCommentDashboard>> = computed(() => {
    return currentOrganisationDashboards.value.filter(x => x.type === 'comments') as Array<HubCommentDashboard>
  })
  const currentDashboard: ComputedRef<DashboardLibraryItem | HubDashboard | HubCommentDashboard | undefined>
    = computed(() => allDashboards.value.find(x => x.id === currentDashboardId.value))

  // functions
  function setCurrentDashboardId(dashboardId: string) {
    currentDashboardId.value = dashboardId
  }
  async function fetchDashboardsForOrganisation(
    rootOrgId: number,
    forceUpdate: boolean = false,
    updateStore: boolean = true,
    query?: object):
    Promise<Array<DashboardLibraryItem>> {
    if (dashboardByOrganisationList.value.length && !forceUpdate) {
      const orgDashboards = dashboardByOrganisationList.value.find(item => item.rootOrgId === rootOrgId)
      if (orgDashboards) {
        return orgDashboards.dashboards
      }
    }

    const dashboards = await $hubFetch<{
      count: number
      items: Array<DashboardLibraryItem>
    }>(`/api/v4/organisations/${rootOrgId}/dashboards`, { query })

    if (updateStore) {
      dashboardByOrganisationList.value.push({
        rootOrgId: rootOrgId,
        dashboards: dashboards?.items
      })
    }

    return dashboards?.items
  }

  async function fetchDashboard(dashboardId: string, forceUpdate: boolean = false):
  Promise<HubDashboard | HubCommentDashboard> {
    const dashboard = allDashboards.value.find(x => x.id === dashboardId)
    if (dashboard && Object.keys(dashboard).includes('config') && !forceUpdate) {
      return dashboard as HubDashboard | HubCommentDashboard
    }

    const data = (await $hubFetch(`api/v4/dashboards/${dashboardId}`)) as HubDashboard

    if (!data) {
      throw createError({ statusCode: 404, statusMessage: 'Dashboard Not Found', fatal: true })
    }

    updateDashboard(dashboardId, data.rootOrganisationNodeId, data)

    return data
  }

  async function fetchDashboardMetadata(dashboardId: string, forceUpdate: boolean = false) {
    const dashboard = allDashboards.value.find(x => x.id === dashboardId)
    if (!dashboard) {
      throw Error('Dashboard Not Found')
    }

    if (Object.keys(dashboard).includes('metadata') && !forceUpdate) {
      return (dashboard as HubDashboard | HubCommentDashboard).metadata
    }

    const metadata = await $hubFetch(`api/v4/dashboards/${dashboardId}/metadata`) as HubDashboardMetadata

    updateDashboard(dashboardId, dashboard.rootOrganisationNodeId, metadata, 'metadata')
  }

  async function updateDashboard<HubDashboardItemKey extends keyof HubDashboard>(
    dashboardId: string,
    rootOrgId: number,
    updateBody: HubDashboard | HubDashboard[HubDashboardItemKey],
    itemKey?: HubDashboardItemKey
  ) {
    const organisationDashboards = dashboardByOrganisationList.value.find(item => item.rootOrgId === rootOrgId)

    let dashboardIndex = -1
    let dashboardList: Array<DashboardLibraryItem | HubDashboard | HubCommentDashboard>
      = organisationDashboards?.dashboards || []
    if (!organisationDashboards) {
      dashboardList = await fetchDashboardsForOrganisation(rootOrgId)
    }

    dashboardIndex = dashboardList?.findIndex(item => item.id === dashboardId)
    if (!dashboardList || !dashboardList.length || dashboardIndex === -1) {
      throw Error('Dashboard Could Not Be Updated')
    }

    if (itemKey) {
      dashboardList[dashboardIndex] = {
        ...dashboardList[dashboardIndex],
        [itemKey]: updateBody as HubDashboard[HubDashboardItemKey]
      }
    } else {
      dashboardList[dashboardIndex] = updateBody as HubDashboard
    }

    const orgIndex = dashboardByOrganisationList.value.findIndex(item => item.rootOrgId === rootOrgId)
    if (orgIndex === -1) {
      dashboardByOrganisationList.value.push({
        rootOrgId: rootOrgId,
        dashboards: dashboardList
      })
    } else {
      dashboardByOrganisationList.value[orgIndex] = {
        rootOrgId: rootOrgId,
        dashboards: dashboardList
      }
    }

    return dashboardList[dashboardIndex]
  }

  return {
    // only used internally but need to be returned
    currentDashboardId,
    dashboardByOrganisationList,

    // public things
    currentDashboard,
    currentOrganisationDashboards,
    currentOrganisationCommentDashboards,
    allDashboards,

    fetchDashboard,
    fetchDashboardsForOrganisation,
    fetchDashboardMetadata,
    updateDashboard,
    setCurrentDashboardId
  }
})
