import type { UseFetchOptions } from 'nuxt/app'
import { defu } from 'defu'
import type { HubSession } from '~/types'
import { responseTypes } from '~/utils/general-helpers'

export default async <T>(url: string, options: UseFetchOptions<T> = {}, responseType: 'json' | 'xlsx' = 'json') => {
  const runtimeConfig = useRuntimeConfig()
  const { data: authData, status } = useAuth()
  const userSession = authData.value as HubSession

  if (status.value === 'unauthenticated') {
    return {
      data: ref(),
      error: ref({
        statusCode: 401,
        statusMessage: 'Unauthenticated',
        message: 'unauthenticated'
      })
    }
  }

  const additionalHeaders = { Accept: responseTypes[responseType] }

  const defaults: UseFetchOptions<T> = {
    baseURL: runtimeConfig.public.apiBase,
    // cache request
    key: url,

    // set user token if connected
    headers:
      userSession && userSession.accessToken
        ? {
            Authorization: `Bearer ${userSession.accessToken}`,
            ...additionalHeaders
          }
        : additionalHeaders,

    onResponseError(_ctx) {
      // TODO: find a way to make this show developers / admins only maybe?
      useHubToast(
        {
          title: _ctx.response._data.status + ' API Error',
          description: _ctx.response._data.detail
        },
        'danger'
      )

      throw new Error(_ctx.response._data.status + ' ' + _ctx.response._data.detail)
    }
  }

  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaults)

  return await useFetch(url, params)
}
