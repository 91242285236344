/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia'
import type {
  HubOrganisationPermission,
  HubSession,
  HubUser,
  HubUserOrganisationPermission,
  HubUserPreference,
  UserInfo
} from '~/types'

export const useUserStore = defineStore('user', () => {
  const dashboardStore = useDashboardStore()
  const { allDashboards } = storeToRefs(dashboardStore)

  const router = useRouter()
  const runtimeConfig = useRuntimeConfig()
  const identityServer = runtimeConfig.public.identityProviderBase

  const currentUser: Ref<HubUser | undefined> = ref()
  const currentUserPreferences: Ref<Array<HubUserPreference>> = ref([])

  const getCurrentUser: ComputedRef<HubUser | undefined> = computed(() => currentUser.value)
  const getCurrentUserPreferences: ComputedRef<Array<HubUserPreference>> = computed(() => currentUserPreferences.value)
  const currentUserDefaultDashboardId: ComputedRef<string | undefined> = computed(() => {
    const defaultDashboard = allDashboards.value.find(dashboard => dashboard.isDefault)
    return defaultDashboard?.id
  })

  const userHomePage: ComputedRef<string> = computed(() => {
    if (allDashboards.value.length > 0) {
      const firstNormalDashboard = allDashboards.value.find(dashboard =>
        !dashboard.type || dashboard.type === 'default'
      )

      if (firstNormalDashboard || currentUserDefaultDashboardId.value) {
        return '/dashboard/' + (currentUserDefaultDashboardId.value || firstNormalDashboard?.id)
      }
    }

    return ''
  })

  async function fetchUserInfo(accessToken: string, tryRefresh = true) {
    try {
      const userInfo = await $fetch<UserInfo>(`${identityServer}/connect/userinfo`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      return userInfo
    } catch (error: any) {
      if (error.statusCode === 401 && tryRefresh) {
        const { data, getSession } = useAuth()
        const session = data.value as HubSession

        if (session?.user?.impersonatorName) {
          router.push('/login?signOut=true')
          return
        }

        await getSession()
        return await fetchUserInfo(session.accessToken, false)
      }

      throw new Error('Failed to fetch user info')
    }
  }

  async function fetchCurrentUserPermissions() {
    try {
      const { data } = useAuth()
      const session = data.value as HubSession
      const currentUserRole = await $hubFetch('/api/v4/roles/users/current') as {
        roleId: string
        permissionIds: Array<string>
      }

      if (!currentUserRole) {
        return
      }

      currentUser.value = {
        ...session.user,
        permissions: currentUserRole.permissionIds
      }
    } catch {
      return undefined
    }
  }

  async function fetchCurrentUser() {
    if (currentUser.value) {
      return currentUser.value
    }

    try {
      await fetchCurrentUserPermissions()

      return currentUser.value
    } catch (error: any) {
      console.warn(error.value?.statusMessage || error)
    }
  }

  async function fetchCurrentUserPreferences() {
    try {
      currentUserPreferences.value = (await $hubFetch('/api/v4/users/current/preferences')) as Array<HubUserPreference>

      return currentUserPreferences.value
    } catch (error: any) {
      console.warn(error.value?.statusMessage || error)
    }
  }

  function fetchImpersonatorName() {
    try {
      const { data } = useAuth()
      const session = data.value as HubSession
      return session?.user?.impersonatorName
    } catch {
      return undefined
    }
  }

  function findRootNodePermissions(permissions: HubOrganisationPermission, rootOrganisationNodeId: number) {
    if (permissions.item.organisationNodeId === rootOrganisationNodeId) {
      return permissions.item.calculatedPermission
    }

    const children = permissions.children
    if (!children) {
      return undefined
    }

    for (const child of children) {
      const childPermissions = findRootNodePermissions(child, rootOrganisationNodeId)
      if (childPermissions) {
        return permissions.item.calculatedPermission
      }
    }

    return undefined
  }

  function checkIsAdmin(userDetails: HubUserOrganisationPermission, rootOrganisationNodeId: number) {
    const rootNodePermissions = findRootNodePermissions(userDetails.permissions, rootOrganisationNodeId)
    if (!rootNodePermissions) {
      return false
    }

    return rootNodePermissions.includes('manageUsers')
  }

  return {
    currentUser,
    currentUserPreferences,

    getCurrentUser,
    getCurrentUserPreferences,
    currentUserDefaultDashboardId,
    userHomePage,

    fetchCurrentUser,
    fetchCurrentUserPreferences,
    fetchCurrentUserPermissions,
    fetchUserInfo,
    fetchImpersonatorName,

    checkIsAdmin
  }
})
